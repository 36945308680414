import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';

const Cases = () => {
	return (
		<div id="page-wrap">
		    <div id="second">
				<Helmet>
					<title>制作事例 | 有限会社フェルマータ</title>
					<meta name="description" content="フェルマータの制作事例を紹介します。" />
				</Helmet>

				<article>
					<h2>制作事例<br /><span>cases</span><br /></h2>
					<p className="main_image"><img src={`${process.env.PUBLIC_URL}/IMAGES/Cases/cases_main.svg`} alt="制作事例" /></p>

					<h3>主な制作事例</h3>
					<p>制作事例の多くはサービスの特性上、お⾒せすることができません。こちらに紹介しているのは、直接取引のある許可をいただいたお客様の事例のみとなります。私たちがどんなことをやってきたのか、ほんの一部ですがご覧ください。</p>

					<div className="cases">
						<p><img src={`${process.env.PUBLIC_URL}/IMAGES/Cases/cases_img01.png`} alt="公益財団法人ジョイセフ Webサイト" /></p>
						<dl>
							<dt><span>企画 | システム開発全般 | 決済（募金）システム | CMS</span><br />公益財団法人ジョイセフ 様</dt>
							<dd>
								Web担当の方とユーザフレンドリーなコンテンツを念頭に、メニューから刷新。ヒトを介して「伝わる」ことを目指してコンテンツ設計。オンライン募金システムでは、支援者の管理システムと連動すことで経理の手間を大幅に削減。マンスリー支援者の会員サイト、Sendgrid APIと連携。<br />
								<a href="https://joicfp.or.jp/" target="_blank">https://joicfp.or.jp/</a>
							</dd>
						</dl>
					</div>

					<div className="cases">
						<p><img src={`${process.env.PUBLIC_URL}/IMAGES/Cases/cases_img02.png`} alt="KOOEN株式会社 MILL" /></p>
						<dl>
							<dt><span>企画 | システム開発全般 | API連携</span><br />KOOEN株式会社 様</dt>
							<dd>
								クリエイティブ業界向けの業務管理システムを共同開発。案件の進行管理、工数管理、外注管理、経理財務管理などの機能があり、GoogleカレンダーAPIや会計クラウドサービスfreeeとの連携、LINEのOCR（文字認識）機能を利用した経費精算といった便利機能を実装。<br />
								<a href="https://kooen.jp/" target="_blank">https://kooen.jp/</a>
							</dd>
						</dl>
					</div>

					<h3>その他の制作事例</h3>
					<p>主にWeb制作会社や広告代理店からご発注いただいた案件を紹介します。</p>

					<h4 id="cases_1" className="cases">フロントエンド開発</h4>
					<div className="scroll-table">
						<table className="cases">
							<thead>
								<tr>
									<th>エンドクライアント</th>
									<th>概要</th>
									<th>企画</th>
									<th>デザイン</th>
									<th>html, CSS, JS</th>
									<th>React</th>
									<th>システム<br />開発</th>
									<th>PC<br />向け</th>
									<th>スマホ<br />向け</th>
									<th>CMS</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>大手家電メーカー</td>
									<td>コミュニティサイト<br />（フロントエンドのみ）</td>
									<td></td>
									<td></td>
									<td>●</td>
									<td></td>
									<td></td>
									<td>●</td>
									<td>●</td>
									<td></td>
								</tr>
								<tr>
									<td>地方議員</td>
									<td>ホームページ制作</td>
									<td>●</td>
									<td>●</td>
									<td>●</td>
									<td>●</td>
									<td></td>
									<td>●</td>
									<td>●</td>
									<td></td>
								</tr>
								<tr>
									<td>旅行会社</td>
									<td>予約システム<br />（フロントエンドのみ）</td>
									<td></td>
									<td></td>
									<td>●</td>
									<td></td>
									<td></td>
									<td>●</td>
									<td>●</td>
									<td></td>
								</tr>
								<tr>
									<td>大手自動車メーカー</td>
									<td>社内マニュアル制作</td>
									<td></td>
									<td></td>
									<td>●</td>
									<td></td>
									<td></td>
									<td>●</td>
									<td></td>
									<td></td>
								</tr>
							</tbody>
						</table>
					</div>

					<h4 id="cases_2" className="cases">Webシステム開発</h4>
					<div className="scroll-table">
						<table className="cases">
							<thead>
								<tr>
									<th>エンドクライアント</th>
									<th>概要</th>
									<th>企画</th>
									<th>デザイン</th>
									<th>html, CSS, JS</th>
									<th>React</th>
									<th>システム<br />開発</th>
									<th>PC<br />向け</th>
									<th>スマホ<br />向け</th>
									<th>CMS</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>医療機器メーカー</td>
									<td>業務管理システム<br />移動経路提案システム<br />業務マッチングシステム</td>
									<td>●</td>
									<td>●</td>
									<td>●</td>
									<td></td>
									<td>●</td>
									<td>●</td>
									<td>●</td>
									<td></td>
								</tr>
								<tr>
									<td>大手食品メーカー</td>
									<td>業務管理システム</td>
									<td>●</td>
									<td>●</td>
									<td>●</td>
									<td></td>
									<td>●</td>
									<td>●</td>
									<td></td>
									<td></td>
								</tr>
								<tr>
									<td>ヨガスタジオ運営</td>
									<td>予約システム<br />顧客管理システム</td>
									<td></td>
									<td></td>
									<td>●</td>
									<td></td>
									<td>●</td>
									<td>●</td>
									<td>●</td>
									<td></td>
								</tr>
								<tr>
									<td>衣料メーカー</td>
									<td>衣料品検品システム</td>
									<td>●</td>
									<td>●</td>
									<td>●</td>
									<td></td>
									<td>●</td>
									<td>●</td>
									<td></td>
									<td></td>
								</tr>
								<tr>
									<td>珈琲豆卸売り</td>
									<td>ショッピングカート<br />顧客管理システム<br />配送管理システム<br />在庫管理システム</td>
									<td></td>
									<td></td>
									<td>●</td>
									<td></td>
									<td>●</td>
									<td>●</td>
									<td>●</td>
									<td></td>
								</tr>
							</tbody>
						</table>
					</div>

					<h4 id="cases_3" className="cases">CMS（Wordpress）テーマ作成</h4>
					<div className="scroll-table">
						<table className="cases">
							<thead>
								<tr>
									<th>エンドクライアント</th>
									<th>概要</th>
									<th>企画</th>
									<th>デザイン</th>
									<th>html, CSS, JS</th>
									<th>React</th>
									<th>システム<br />開発</th>
									<th>PC<br />向け</th>
									<th>スマホ<br />向け</th>
									<th>CMS</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>保育園</td>
									<td>Wordpressテーマ作成</td>
									<td>●</td>
									<td>●</td>
									<td>●</td>
									<td></td>
									<td></td>
									<td>●</td>
									<td>●</td>
									<td>●</td>
								</tr>
								<tr>
									<td>イベント会社</td>
									<td>Wordpressテーマ作成</td>
									<td>●</td>
									<td>●</td>
									<td>●</td>
									<td></td>
									<td></td>
									<td>●</td>
									<td>●</td>
									<td>●</td>
								</tr>
								<tr>
									<td>出版社</td>
									<td>Wordpressテーマ作成</td>
									<td>●</td>
									<td>●</td>
									<td>●</td>
									<td></td>
									<td>●</td>
									<td>●</td>
									<td>●</td>
									<td>●</td>
								</tr>
							</tbody>
						</table>
					</div>

					<h4 id="cases_4" className="cases">ホームページ制作</h4>
					<div className="scroll-table">
						<table className="cases">
							<thead>
								<tr>
									<th>エンドクライアント</th>
									<th>概要</th>
									<th>企画</th>
									<th>デザイン</th>
									<th>html, CSS, JS</th>
									<th>React</th>
									<th>システム<br />開発</th>
									<th>PC<br />向け</th>
									<th>スマホ<br />向け</th>
									<th>CMS</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>大手化粧品メーカー<br />（東証プライム）</td>
									<td>ホームページ制作</td>
									<td></td>
									<td></td>
									<td>●</td>
									<td></td>
									<td></td>
									<td>●</td>
									<td></td>
									<td></td>
								</tr>
								<tr>
									<td>大手製薬会社</td>
									<td>ホームページ制作</td>
									<td></td>
									<td></td>
									<td>●</td>
									<td></td>
									<td></td>
									<td>●</td>
									<td></td>
									<td></td>
								</tr>
							</tbody>
						</table>
					</div>
				</article>
			</div>

			<div id="message">
				<p><strong>Form Follows Function</strong></p>
				<p>「形態は機能に従う」</p>
				<p>ルイス・サリヴァン（1856-1924）</p>
			</div>
		</div>
	);
};

export default Cases;