import { Outlet, Link  } from "react-router-dom";
import Sidebar from "./Sidebar";

const Default = () => {
	return (
		<div className="App" id="outer-container">
			<header id="header">
				<Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />
				<h1><Link to={'/'}><img src={`${process.env.PUBLIC_URL}/IMAGES/logo.svg`} alt="有限会社フェルマータ" /></Link></h1>
			</header>
			<Outlet />
			<footer id="footer">
				<dl>
					<dt>東京都練馬区にあるWeb制作会社フェルマータです</dt>
					<dd>
						<ul>
							<li><a href="tel:0339933610"><strong>03-3993-3610</strong><br />平日9:00～18:00</a></li>
							<li><a href="https://docs.google.com/forms/d/e/1FAIpQLSd4beZ_G7kXcsItdJMTABbotdmcLtEiP7qCrj_-JsOdabdwGA/viewform?usp=sf_link" target="_blank"><strong>Googleフォーム</strong><br />からお問い合わせする</a></li>
							<li><a href="https://m.me/fermata.co.jp" target="_blank"><strong>Messenger</strong><br />からお問い合わせする</a></li>
							<li><a href="https://liff.line.me/1645278921-kWRPP32q?accountId=024qxylh&openerPlatform=native&amp;openerKey=talkroom%3Aheader#mst_challenge=j7ilguY0kTjKYzhXFYGdTuo9HCKShosNQfXUl7Ip4yU" target="_blank"><strong>LINE@</strong><br />からお問い合わせする</a></li>
						</ul>
					</dd>
				</dl>
				<p>
					※ 弊社はリモートワークを推奨しております。お電話に出られないときは、折り返しますのでご用件と連絡先を残してください。<br />
				</p>
			</footer>
		</div>
	);
};
export default Default;