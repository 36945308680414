import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';

const Company = () => {
	return (
		<div id="page-wrap">
		    <div id="second">
				<Helmet>
					<title>会社概要 | 有限会社フェルマータ</title>
					<meta name="description" content="フェルマータの企業データを紹介します。" />
				</Helmet>

				<article>
					<h2>会社概要<br /><span>company overvie</span></h2>
					<p className="main_image"><img src={`${process.env.PUBLIC_URL}/IMAGES/Company/company_main.svg`} alt="会社概要" /></p>
					<table className="company">
						<tbody>
							<tr>
								<th>商号</th>
								<td>
									有限会社フェルマータ
								</td>
							</tr>
							<tr>
								<th>設立</th>
								<td>2002年1月18日（創立 2000年5月14日）</td>
							</tr>
							<tr>
								<th>代表者</th>
								<td>コグレリョウヘイ</td>
							</tr>
							<tr>
								<th>資本金</th>
								<td>3,000,000円</td>
							</tr>
							<tr>
								<th>所在地</th>
								<td>
									〒176-0012 <a href="http://maps.google.co.jp/maps/ms?hl=ja&amp;q=&amp;ie=UTF8&amp;om=1&amp;z=16&amp;ll=35.736986,139.659147&amp;spn=0.011112,0.015278&amp;msid=117951303382601928991.00000111d450750463e32&amp;msa=0" target="_blank">東京都練馬区豊玉北4-5-15 1F</a>
								</td>
							</tr>
							<tr>
								<th>事業内容</th>
								<td>
									<ol>
										<li>
											<dl>
												<dt><strong>Web制作事業</strong><br /><span>（一般第二種電気通信事業者：A-13-5076）</span></dt>
												<dd>
													<ul>
														<li>Webサイト制作</li>
														<li>Webシステム開発</li>
														<li>Webアプリケーション開発</li>
														<li>Webサイト/Webシステムの保守・運営</li>
													</ul>
												</dd>
											</dl>
										</li>
										<li>
											<dl>
												<dt><strong>Web PR/アドバイザリー事業</strong></dt>
												<dd>
													<ul>
														<li>Web PR / マーケティング</li>
														<li>SNSマーケティング</li>
													</ul>
												</dd>
											</dl>
										</li>
										<li>
											<dl>
												<dt><strong>音楽事業</strong></dt>
												<dd>
													<ul>
														<li>音楽出版</li>
														<li>レコーディング</li>
														<li>社歌作成（楽曲制作）</li>
													</ul>
												</dd>
											</dl>
										</li>
									</ol>
								</td>
							</tr>
						</tbody>
					</table>
				</article>
			</div>

			<div id="message">
				<p><strong>Less is More</strong></p>
				<p>「より少ないことは、より豊かなこと」</p>
				<p>ミース・ファン・デル・ローエ（1886-1969）</p>
			</div>
		</div>
	);
};

export default Company;