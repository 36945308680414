import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';
import { HashLink } from 'react-router-hash-link';

const Services = () => {
	return (
		<div id="page-wrap">
		    <div id="second">
				<Helmet>
					<title>プロに選ばれるサービス | 有限会社フェルマータ</title>
					<meta name="description" content="フェルマータは、ビジネスの要求と機能性のバランスを考慮した最適なソリューションを提供します。多様化・高度化し続けるWebメディアにおいて、エンゲージメントを高めるコミュニケーション戦略を提案します。" />
				</Helmet>

				<article>
					<h2>プロに選ばれるサービス<br /><span>our services</span><br /></h2>
					<p className="main_image"><img src={`${process.env.PUBLIC_URL}/IMAGES/Services/services_main.svg`} alt="プロに選ばれる理由" /></p>

					<h3>お客様ではなくパートナー</h3>
					<p>2000年の創業以来、東京都内はもとより横浜や千葉といった関東から大阪にいたるお客様のアライアンス先として使い続けられている理由は、スピードとクオリティ、柔軟な対応にあると自負しております。</p>
					<p>その先のお客様の笑顔が見たいから、こんなに喜ばれたよと言われたいから、時には一緒に「最善」や「最良」を悩み、時には一緒に朝まで飲み明かしたり、雨に打たれることも靴の底を減らすことも惜しまず私たちは信頼を築き上げてきました。</p>
					<p>そして、どなたもその道のプロばかりです。私たちだけではリソースが足りないときは安心して協力をお願いできる、困ったときには相談できる仲間たちでもあります。</p>

					<ul className="services">
						<li>
							<dl>
								<dt><span>jQuery から React まで</span><br />フロントエンド開発</dt>
								<dd>
									<img src={`${process.env.PUBLIC_URL}/IMAGES/Services/services_img01.svg`} alt="フロントエンド開発" />
									<p>
										フロントエンドにリソースを割くことができない、バックエンド開発を主体とするお客様からのご発注もお受けしております。<br />
										<HashLink to="/cases#cases_1" className="button_1">制作事例</HashLink>
									</p>
								</dd>
							</dl>
						</li>
						<li>
							<dl>
								<dt><span>美しいコードよりも「使いやすいインターフェース」</span><br />Webシステム開発</dt>
								<dd>
									<img src={`${process.env.PUBLIC_URL}/IMAGES/Services/services_img02.svg`} alt="Webシステム開発" />
									<p>
										ストイックな開発者が陥りやすい「美しいコードの追求」よりも、弊社ではバランス感覚を大切にし、「使いやすいインターフェース」を最重要に設計、開発をしております。<br />
										<HashLink to="/cases#cases_2" className="button_1">制作事例</HashLink>
									</p>
								</dd>
							</dl>
						</li>
						<li>
							<dl>
								<dt><span>プラグイン依存の少ないテーマだから長く使える</span><br />CMS（Wordpress）テーマ作成</dt>
								<dd>
									<img src={`${process.env.PUBLIC_URL}/IMAGES/Services/services_img03.svg`} alt="CMS（Wordpress）テーマ作成" />
									<p>
										オリジナルのデザインをWordpressに組込みます。サイト内検索や、Blogの組込みなど、Wordpressならではの機能を残したまま、オリジナルのテーマを開発します。<br />
										<HashLink to="/cases#cases_3" className="button_1">制作事例</HashLink>
									</p>
								</dd>
							</dl>
						</li>
						<li>
							<dl>
								<dt><span>目的にあったプラン策定</span><br />ホームページ制作</dt>
								<dd>
									<img src={`${process.env.PUBLIC_URL}/IMAGES/Services/services_img04.svg`} alt="Webサイト制作" />
									<p>
										現在の課題をヒアリングし、何がよりベターかをともに考え、企画いたします。<br />
										また予算に応じて、ノーコードで制作するWix、BASE、Storesといったサービスを利用したサイト構築のご相談も承ります。<br />
										<HashLink to="/cases#cases_4" className="button_1">制作事例</HashLink>
									</p>
								</dd>
							</dl>
						</li>
						<li>
							<dl>
								<dt><span>継続率99.9%。Web担当のアウトソーシング</span><br />Webサイト運営（更新代行）</dt>
								<dd>
									<img src={`${process.env.PUBLIC_URL}/IMAGES/Services/services_img05.svg`} alt="Webサイト運営（更新代行）" />
									<p>テキストや画像の差し替えといった修正からお知らせの投稿、SEO改善などWebサイトの更新代行を承っております。また、ドメイン・サーバといったWebサイト周りのご質問は、お電話やメールにていつでもお問い合わせいただけます。</p>
								</dd>
							</dl>
						</li>
						<li>
							<dl>
								<dt><span>伝えたい方にいかに届けるかを一緒に考えます</span><br />Web PR /マーケティング</dt>
								<dd>
									<img src={`${process.env.PUBLIC_URL}/IMAGES/Services/services_img06.svg`} alt="Web PR /マーケティング" />
									<p>胸に「セレブ」と書かれたＴシャツを着た人を見て「あ～本物のセレブだ！」と思うでしょうか？あなたのメッセージは、セレブと自ら言ってませんか？<br />「答え」を求めると答えを持つものにすがります。自分の中に「問い」を持ち続けられるよう伴走いたします。</p>
								</dd>
							</dl>
						</li>
					</ul>
				</article>
			</div>

			<div id="message">
				<p><strong>The MIND is like an umbrella.<br />It functions best when OPEN.</strong></p>
				<p>「人の心とは傘のようなものだ。開いた時に最も機能する」</p>
				<p>ヴォルター・グロピウス（1885-1967）</p>
			</div>
		</div>
	);
};

export default Services;