import React from 'react';
import { Link } from "react-router-dom";
import { slide as Menu } from 'react-burger-menu';
import './Sidebar.css';

export default props => {
	const [menuOpen, setMenuOpen] = React.useState(false)

	const handleStateChange = state => {
		setMenuOpen(state.isOpen)
	}

	const closeMenu = () => {
		setMenuOpen(false)
	}

	return (
		<Menu isOpen={menuOpen} onStateChange={state => handleStateChange(state)}>
			<ul>
				<li><Link onClick={() => { closeMenu() }} to={'/'}>トップページ</Link></li>
				<li><Link onClick={() => { closeMenu() }} to={'/services'}>プロに選ばれるサービス</Link></li>
				<li><Link onClick={() => { closeMenu() }} to={'/cases'}>制作事例</Link></li>
				<li><Link onClick={() => { closeMenu() }} to={'/company'}>会社概要</Link></li>
			</ul>
		</Menu>
	)
}