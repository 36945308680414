import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';

import AnimationTrigger from "./AnimationTrigger";

const Home = () => {
	return (
		<div id="page-wrap">
		    <div id="home">
				<Helmet>
					<title>有限会社フェルマータ | Web制作会社がクライアントのWeb制作会社</title>
					<meta name="description" content="東京都練馬区にあるWeb制作会社です。ホームページの構築・運用、Webシステムの開発を中心とした様々なサービスで、コミュニケーションを創造・改善します。" />
				</Helmet>

				<article>
					<h2>東京都練馬区にある<br />Web制作会社<br />フェルマータです</h2>
					<p><img style={{float: 'right', width:'50%', padding:'0 0 20px 20px'}} src={`${process.env.PUBLIC_URL}/IMAGES/Home/home_main.svg`} alt="開発イメージ" />ホームページの企画・制作・運営、WebシステムやWebアプリケーション開発、Web PRを生業としておりますが、音楽出版もちょことやったりします。</p>
					<p>ほかのWeb制作会社と異なるのは、主な発注元が「コーディングの苦手なデザイン会社」「デザインからコーディングまでは行うけれどシステム開発のできないWeb制作会社」であること。<br />その中でも特に「Webのプロフェッショナル」の企業から外注先としてご利用いただいております。</p>
				</article>

				<article>
					<h2>私たちのお仕事</h2>
					<ul className="works">
						<li>
							<img src={`${process.env.PUBLIC_URL}/IMAGES/Home/home_img01.png`} alt="Webサイト制作/更新代行" />
							<dl>
								<dt>Webサイト制作/更新代行</dt>
								<dd>ホームページやCMS（Wordpress）のオリジナルテーマ開発、Webサイトの運営代行をしています。</dd>
							</dl>
						</li>
						<li>
							<img src={`${process.env.PUBLIC_URL}/IMAGES/Home/home_img02.png`} alt="Webシステム開発" />
							<dl>
								<dt>Webシステム開発</dt>
								<dd>ブラウザベースのアプリをGoogle、LINE、freeeなどのAPIと連携させながら開発しています。</dd>
							</dl>
						</li>
						<li>
							<dl>
								<img src={`${process.env.PUBLIC_URL}/IMAGES/Home/home_img03.png`} alt="Web PR / マーケティング" />
								<dt>Web PR / マーケティング</dt>
								<dd>「伝える」から「伝わる」、「知る」から「わかる」を通したコミュニケーションをお手伝いします。</dd>
							</dl>
						</li>
						<li>
							<img src={`${process.env.PUBLIC_URL}/IMAGES/Home/home_img04.png`} alt="音楽出版" />
							<dl>
								<dt>音楽出版</dt>
								<dd>アーティストの育成、楽曲制作、レコーディング、楽曲の著作権管理などをしています。</dd>
							</dl>
						</li>
					</ul>
				</article>

				<article>
					<h2>お客様の声</h2>
					<p>
						本来であれば制作事例をもとに、どのように喜ばれているかをお伝えしたいところですが、Web制作会社や広告代理店などを経由した仕事が多いため、具体的に紹介できる案件はほとんどありません。<br />
						そこで、ほんの一部ではありますが、お客様の声を紹介させていただきます。<br />
					</p>

					<ul className="voice">
						<li className="left"><AnimationTrigger animation="fadeLeft" rootMargin="100px" triggerOnce><p>もしかしてすごく手間のかかることをお願いしてはいなかったでしょうか？すぐにご対応いただけると思ってはいなかったので、修正版を送っていただき驚きました。😀<br />（大阪市 非営利団体様）</p></AnimationTrigger></li>
						<li className="right"><AnimationTrigger animation="fadeRight" rootMargin="100px" triggerOnce><p>ひと通り動作確認しましたが、問題はありませんでした。<br />CSVアップロードに関しては非常に素晴らしい出来栄えです。<br />本当にありがとうございます。<br />（横浜市 WEB制作会社様）</p></AnimationTrigger></li>
						<li className="left"><AnimationTrigger animation="fadeLeft" rootMargin="100px" triggerOnce><p>んなりがとうございます!!!!!<br />いつもいつも感謝でございます!!!!!!!!!!!!!!!!!!!!!!!!<br />（新宿区 WEB制作会社様）</p></AnimationTrigger></li>
						<li className="right"><AnimationTrigger animation="fadeRight" rootMargin="100px" triggerOnce><p>とても美しいhtmlをご作成頂き、お陰さまで今日一日でほぼプログラムを実装することができました。この度は、窮地を救って頂いて、本当に助かりました。<br />（千葉県 システム会社様）</p></AnimationTrigger></li>
						<li className="left"><AnimationTrigger animation="fadeLeft" rootMargin="100px" triggerOnce><p>無理を言ったのに素早い対応本当にありがとうございました。<br />わかりやすくて助かります。ありがとうございました。とても助かりました。<br />（中央区 ASPサービス会社様）</p></AnimationTrigger></li>
						<li className="right"><AnimationTrigger animation="fadeRight" rootMargin="100px" triggerOnce><p>制作中はもちろん、納品後もこちらの質問等に親切に対応頂きました。ありがとうございました！<br />また、よろしくお願いします！<br />（新宿区 広告代理店様）</p></AnimationTrigger></li>
						<li className="left"><AnimationTrigger animation="fadeLeft" rootMargin="100px" triggerOnce><p>早朝にご返信いただき、申し訳ありませんでした。フェルマータ様とサポート契約をさせていただき良かったとつくづく思いました。<br />（港区 非営利団体様）</p></AnimationTrigger></li>
						<li className="right"><AnimationTrigger animation="fadeRight" rootMargin="100px" triggerOnce><p>確認いたしました!!👍<br />とてつもないスピードでのご対応いただき、ありがとうございました。<br />（中央区 環境メーカー様）</p></AnimationTrigger></li>
					</ul>
				</article>

				<article>
					<h2>よくあるご質問</h2>
					<ul className="faq">
						<li>
							<input id="faq_1" type="checkbox" /><label htmlFor="faq_1">Webサイトやシステムに詳しくありません。サポートいただけますか？</label>
							<p>もちろんです。お客様にわかるように説明をしながら進めていきます。お客様の体制にあったホームページを提案いたします。安心してお任せください。</p>
						</li>
						<li>
							<input id="faq_2" type="checkbox" /><label htmlFor="faq_2">九州の会社ですが、相談やご依頼はできますか</label>
							<p>
								はい。お電話やメールはもちろん、Google Meet や Zooｍ などオンラインでのご相談も可能です。対面でのお打ち合わせが必要な場合は、貴社にうかがいます。<br />
								ご依頼をいただいた後は、お電話やメール、オンラインミーティングツールを使ってやり取りをしながら作業を進めていきますので、距離が離れていることによる支障は全くございません。<br />
							</p>
						</li>
						<li>
							<input id="faq_3" type="checkbox" /><label htmlFor="faq_3">費用の支払い方法と支払いサイクルを教えていただけますか</label>
							<p>
								お支払い方法は、銀行振込でのお支払いになります。支払いサイクルについては都度ご相談ください。<br />
							</p>
						</li>
					</ul>
				</article>
			</div>

			<div id="message">
				<p><strong>U should sing,<br />if U couldn't talk enough.</strong></p>
				<p>「話し足りなければ、歌えばいい」</p>
				<p>有限会社フェルマータ</p>
			</div>
		</div>
	);
};

export default Home;