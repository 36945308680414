import React from 'react';
import './App.css';

import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollToTop from './components/ScrollToTop';

import Default from "./components/Default";
import Home from "./components/Home";
import Services from "./components/Services";
import Cases from "./components/Cases";
import Company from "./components/Company";

function App() {
	return (
		<BrowserRouter>
			<ScrollToTop />
			<Routes>
				<Route element={<Default />}>
					<Route path={'/'} element={<Home />} />
					<Route path={'/services/'} element={<Services />} />
					<Route path={'/cases/'} element={<Cases />} />
					<Route path={'/company/'} element={<Company />} />
				</Route>
			</Routes>
		</BrowserRouter>
	);
}

export default App;
